import { Fade, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Page from "../../components/layouts/Page";
import '../../css/views/public/Home.css';
import image from '../../assets/img/portada.png';
import line from '../../assets/img/line.png';
import anteriors from '../../assets/img/anteriors.png';
import mask from '../../assets/img/mask.png';
import maskportada from '../../assets/img/mask_portada.png';
import ItemCursa from "./Home/ItemCursa";
import Eliminadors from "./Home/Eliminadors";
import arrow from '../../assets/arrow.svg';
import CountdownCasserrenca from "./Home/CountdownCasserrenca";
import logo_gris from '../../assets/logo_negre.png';
import Intro from "./Intro";
import { get } from "../../API/API";
import CarouselCasserrenca from "../../components/elements/CarouselCasserrenca";
import { useNavigate } from "react-router-dom";
import Footer from "../../layouts/public/Footer";

function Home(props) {

	const [loading, setLoading] = useState(true);

	const [value, setValue] = useState(302);

	const [selected, setSelected] = useState(null);

	const [data, setData] = useState([]);

	const navigate = useNavigate();

	const handleChange = (event, newValue) => {
		setValue(newValue);
        const cursa = (data?.curses?.filter(item => 
			item.id == newValue
		));
		setSelected(cursa[0]);
	};

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await get("casserrencaActiva");
			setData(result);
            const cursa = (result?.curses?.filter(item => 
                item.id == value
            ));
            setSelected(cursa[0]);
			setLoading(false);
		};
		obtenir();
    }, []);

	
	
	return (
		<Page title="La Casserrenca" timeout={3000} paddingTop={0}>
				<div className="Logo-fons">
					<img src={logo_gris} className='Imatge-fons'/>
				</div>
				<Box className='Home-portada' sx={{height: (window.innerHeight + 'px')}}>
					<div className="Home-portada-mask">
						<img src={maskportada} className='Home-img'/>
					</div>
					<div className="Home-portada-title">
						<div className="Home-portada-top">
							<Typography variant="h2" color='#fff' textAlign='center'>Pròxima edició</Typography>
                            <Typography variant='h2' color='#fff' textAlign='center' marginBottom='30px' marginTop='10px'>{data?.data_inici}</Typography>
                            <div className="Home-portada-separator" style={{marginBottom: '15px'}}></div>
							<CountdownCasserrenca data={data?.data_inici}/>
						</div>
						<div className="Home-portada-bottom">
							<Typography dangerouslySetInnerHTML={{__html: data?.text_portada}} variant="h4" color='#fff' marginBottom='50px'></Typography>
							<img src={arrow} className='Home-arrow' onClick={() => {document.getElementById("LaCassarrenca").scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"})}}/>
						</div>
					</div>
					{/* <img src={image} className='Home-img'/> */}
					<div style={{position:'relative', zIndex: '-1', height:'100%'}}>
						<CarouselCasserrenca images={[image]} hideDots={true}/>
					</div>
				</Box>
				<Box className='Home-cursa' id='LaCassarrenca'>
					
					<Typography dangerouslySetInnerHTML={{__html: data?.titol}} variant='h2' className="Home-cursa-title" textAlign='center' mt={10}></Typography>

					<Typography dangerouslySetInnerHTML={{__html: data?.info_general}} variant='body1' className="Home-cursa-subtitle" marginTop='30px'></Typography>
					<Tabs
						value={value}
						onChange={handleChange}
						variant="scrollable"
						scrollButtons
						aria-label="visible arrows tabs example"
						sx={{zIndex: 6}}
					>
						{data?.curses?.map((item) => (
							<Tab
								label={
									<div dangerouslySetInnerHTML={{__html: item?.nom}}></div>
								}
								value={item.id}
							/>
						))}
					</Tabs>
					<ItemCursa data={selected}/>
					
				</Box>
				<Eliminadors data={data?.eliminadors}/>
				<div className="Home-anteriors">
					<div className="Home-anteriors-content" style={{maxWidth: '100%'}}>
						{/* <Typography variant="h3" color='#fff' textAlign='center'>Fotos de l'edició 2023</Typography>
						<a href="https://photos.app.goo.gl/QnESG8EpQ47kGEyE8" className="Home-button">

							<Typography variant="body1" className="Home-button-text">Veure fotos</Typography>
						</a> */}
						<a href="https://sportmaniacs.com/es/races/la-casserrenca-2024" target="__blank" style={{cursor:'pointer', zIndex: 6, marginTop: 100}}>

						<Typography variant="h3" className="Home-button-text" textAlign={'center'} color={'#fff !important'}>Consulta les classificacions 2024</Typography>
						</a>
						<Typography variant="h3" color='#fff' textAlign='center' className="Home-cursa-title" mt={3}>Fotos de l'edició 2024</Typography>
						<Box display={'flex'} flexDirection={props.isMobile ? 'column' : 'unset'} mt={2}>
						<a href="https://photos.app.goo.gl/AGpaMDTpLjSr7eWg9" target="__blank" className="Home-button" style={{cursor:'pointer', zIndex: 6}}>

							<Typography variant="body1" className="Home-button-text" textAlign={'center'}>Fotos Geniku</Typography>
						</a>
						<a href="https://photos.app.goo.gl/HjRRUVnux5YE9NrJ9" target="__blank" className="Home-button" style={{cursor:'pointer', zIndex: 6, marginLeft: (props.isMobile? 0 : '20px'), marginRight: '0'}}>

							<Typography variant="body1" className="Home-button-text" textAlign={'center'}>Fotos Josep Riu</Typography>
						</a>
						<a href="https://photos.app.goo.gl/rcHPrN4pM1Q2Up3K8" target="__blank"  className="Home-button" style={{cursor:'pointer', zIndex: 6, marginLeft: (props.isMobile? 0 : '20px'), marginRight: (props.isMobile? 0 : '20px')}}>

							<Typography variant="body1" className="Home-button-text" textAlign={'center'}>Fotos Wildcoast Bikes</Typography>
						</a>
						<a href="https://photos.app.goo.gl/zE5rKkv52HjpBWVe7" target="__blank"  className="Home-button" style={{cursor:'pointer', zIndex: 6,}}>

							<Typography variant="body1" className="Home-button-text" textAlign={'center'}>Altres Fotos</Typography>
						</a>
						</Box>
					</div>
					<div className="Home-mask">
						<img src={mask} className='Home-img'/>
					</div>
					<div className="Home-anteriors-img">
						<img src={anteriors} className='Home-img-anteriors'/>
					</div>
				</div>
				<Footer/>
		</Page>
	);
}

export default Home;
