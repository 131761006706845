import { SvgIcon, Typography } from "@mui/material";
import { Box, color } from "@mui/system";
import React, { useEffect, useState } from "react";
import '../../css/views/public/Home.css';
import '../../css/layouts/public/Menu.css';
import { useNavigate } from 'react-router';
// import LogoIcon from "../../components/elements/LogoIcon";
// import { ReactComponent as LogoIcon } from "../../components/elements/LogoIcon";
import menu from '../../assets/menu.svg';
import close from '../../assets/close.svg';
import shopcar from '../../assets/shopcar.svg';
import logo from '../../assets/logo_blanc.png';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';


const Menu = (props) => {

	const [animation, setAnimation] = useState(true);

	const navigate = useNavigate();

	function onClickMenu() {
		props.f_setOpen(!props.open);
	}

	function onClickItem(item) {
		if (item.title == 'La Casserrenca 2023' && window.location.pathname == '/') {
			console.log('hola')
			document.getElementById("LaCassarrenca").scrollIntoView({ behavior: 'smooth', block: 'end'});
		}
		else {
			navigate(item.link);
		}
		props.f_setOpen(!props.open);
	}

	function totalItems() {
		let count = 0;
		props.shopCart?.map((item, index) => {
			count = count + item.quantity;
		})
		return count;
	}


	return (
		<Box className={"Menu-container "}>
			<div className="Menu-header">
				<div className="Menu-Item" style={{justifyContent: 'flex-start', marginLeft: '10%'}}>
					<div className="Menu-Item-fons" style={{cursor: 'pointer'}} onClick={onClickMenu}>
						{props.open ? <img src={close} className=''/> : <img src={menu} className=''/>}
					</div>
				</div>
				<div className="Menu-Item" style={{justifyContent: 'center'}}>
					<img src={logo} className='Menu-logo' style={{cursor: 'pointer'}} onClick={()=>{navigate('/')}}/>
				</div>
				<div className="Menu-Item" style={{justifyContent: 'flex-end', marginRight: '10%'}}>
					{props.isMobile ? null : 
						<div className="Menu-Item-fons" style={{marginRight: '20px', color:'#fff', fontWeight:'bold'}}>
							<a href="https://inscripcions.cat/lacasserrenca2025/">
								Inscripcions
							</a>
						</div>
					}
					<div className="Menu-Item-fons" onClick={props.toggleDrawer(true)}>
						<img src={shopcar} className=''/>
						<div className="Menu-Item-total">{totalItems()}</div>
					</div>
				</div>
			</div>
			<div className={"Menu-content " + (props.open ? 'menu-opened' : 'menu-closed')}>
				{props.items.map((item, index) => {
					return (
						<div key={index} className="Menu-content-item" onClick={()=> {onClickItem(item)}}>
							<Typography variant='menu' className="Menu-content-item-text">{item.title}</Typography>
						</div>
					)
				})}
				<div className="Menu-content-social">
					<Typography variant='menuSocial' className="Menu-content-social-text"><a href="https://www.youtube.com/@lacasserrenca3175"><YouTubeIcon sx={{width: "50px", height: "100%"}}/></a></Typography>
					<Typography variant='menuSocial' className="Menu-content-social-text" style={{marginLeft: '20px'}}><a href="https://www.instagram.com/la_casserrenca/?hl=es"><InstagramIcon  sx={{width: "35px", height: "100%"}}/></a></Typography>
				</div>
			</div>
		</Box>
	);
}

export default Menu;
